import {
  Badge,
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Iconify from "src/components/Iconify";
import MenuPopover from "src/components/MenuPopover";
import Scrollbar from "src/components/Scrollbar";
import { IconButtonAnimate } from "src/components/animate";
import { DATE_FORMAT } from "src/config";
import useIsUser from "src/hooks/use-is-user";
import useAuth from "src/hooks/useAuth";
import useErrors from "src/hooks/useErrors";
import axiosInstance from "src/utils/axios";
import fetchUser from "src/utils/fetchUser";
import notificationSound from "src/images/video/simple-notification-152054.mp3";
const useChangeNotificationStatus = (fetchData) => {
  const handleError = useErrors();
  const { isAdmin } = useAuth();

  const changeStatus = async (id) => {
    const URI = isAdmin ? "api/admin/notifications" : "api/user/notifications";

    const reqData = new FormData();
    reqData.append("_method", "PUT");
    try {
      const { data, status } = await axiosInstance.post(
        `${URI}/${id}`,
        reqData
      );

      if (status === 200) {
        fetchData();
      }
    } catch (err) {
      handleError(err);
    }
  };

  return changeStatus;
};

export const useStyles = makeStyles(() =>
  createStyles({
    swingIcon: {
      animation: "$swing 1.5s ease-in-out infinite",
      transformOrigin: "top center", // Swing starts from the top center
    },
    "@keyframes swing": {
      "0%": {
        transform: "rotate(0deg)",
        boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.2)", // Initial shadow
      },
      "20%": {
        transform: "rotate(20deg)",
        boxShadow: "5px 10px 20px rgba(0, 0, 0, 0.2)", // Shadow changes to simulate movement
      },
      "40%": {
        transform: "rotate(-15deg)",
        boxShadow: "-5px 10px 20px rgba(0, 0, 0, 0.2)",
      },
      "60%": {
        transform: "rotate(10deg)",
        boxShadow: "3px 7px 18px rgba(0, 0, 0, 0.2)",
      },
      "80%": {
        transform: "rotate(-5deg)",
        boxShadow: "-2px 5px 16px rgba(0, 0, 0, 0.2)",
      },
      "100%": {
        transform: "rotate(0deg)",
        boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)", // Return to original shadow
      },
    },
  })
);

export const useNotification = () => {
  const [notifications, setNotifications] = useState([]);
  const handleError = useErrors();
  const isUser = useIsUser();
  const { isAdmin } = useAuth();
  const fetchData = async (isUser) => {
    const URI = isAdmin ? "api/admin/notifications" : "api/user/notifications";
    try {
      const { data, status } = await axiosInstance(URI);
      if (status === 200) {
        setNotifications(data.data);
      }
    } catch (err) {
      handleError(err);
    }
  };
  useEffect(() => {
    fetchData(isUser);
  }, [isUser]);

  return { notifications, fetchData };
};

export default function NotificationsPopover() {
  const { notifications, fetchData } = useNotification();
  const totalUnRead = notifications.filter((item) => item.is_read === 0).length;
  const changeStatus = useChangeNotificationStatus(fetchData);
  const [open, setOpen] = useState(null);

  const [showNotification, setShowNotification] = useState(false);
  const [soundPlayed, setSoundPlayed] = useState(false); // Track if the sound has already played

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play().catch((error) => {
      console.error("Failed to play sound:", error);
    });
  };

  const triggerNotification = () => {
    setShowNotification(true);
    if (!soundPlayed) {
      playNotificationSound();
      setSoundPlayed(true); // Mark that the sound has been played
    }
    setTimeout(() => setShowNotification(false), 1000);
  };

  useEffect(() => {
    if (totalUnRead > 0) {
      triggerNotification();
    } else {
      setSoundPlayed(false); // Reset soundPlayed when there are no unread notifications
    }
  }, [totalUnRead]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {};
  const classes = useStyles();

  return (
    <>
      <IconButtonAnimate
        className={totalUnRead > 0 ? classes.swingIcon : ""}
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40, color: "#3344b5" }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )} */}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List disablePadding>
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                {...notification}
                changeStatus={changeStatus}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ is_read, message, id, changeStatus, created_at }) {
  return (
    <ListItemButton
      onClick={() => changeStatus(id)}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(!is_read && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemText
        primary={message}
        primaryTypographyProps={{
          variant: "body2",
        }}
        secondaryTypographyProps={{
          variant: "caption",
          marginTop: 1,
        }}
        secondary={moment(created_at).format(`h:mma ${DATE_FORMAT}`)}
      />
    </ListItemButton>
  );
}
